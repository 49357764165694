
export default {
  name: 'AtomVideoSlider',
  props: {
    sliderVideo: {
      required: true,
      type: Object,
      default: () => {
        return {
          video: '',
          image: '',
          thumb: ''
        }
      }
    }
  },
  data() {
    return {
      isPlaying: false
    }
  },
  computed: {
    cdnBase() {
      return this.$store.getters['base/cdnBase']
    },
    isHostedVideo() {
      return !(!this.assetUrl.includes('youtube') && !this.assetUrl.includes('vimeo'))
    },
    assetUrl() {
      if (this.sliderVideo.video.includes('://')) {
        return this.sliderVideo.video
      }
      return `${this.cdnBase}/${this.sliderVideo.video}`
    }
  },
  methods: {
    cdnImage(image) {
      return this.$store.getters['base/cdnBase'] + image
    },
    play() {
      this.$refs.video.play()
      this.isPlaying = true
    },
    playIframe() {
      this.isPlaying = true
    },
    resourceUrl(img) {
      return `${this.$store.getters['base/cdnBase']}/${img}`
    }
  }
}
