
export default {
  name: 'AtomImageThumbSlider',
  props: {
    sliderImage: {
      required: true,
      type: Object,
      default: () => {
        return {
          image: '',
          thumb: ''
        }
      }
    }
  },
  computed: {
    index() {
      return this.$vnode.data.key
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
