
export default {
  name: 'AtomOption',
  props: {
    option: {
      required: true,
      type: Object,
      default: () => ({
        textValue: 'amenity-name',
        uuid: ''
      })
    },
    value: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      lazyVal: this.value
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyVal
      },
      set(val) {
        this.lazyVal = val
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value(val) {
      this.lazyVal = val
    }
  },
  methods: {
    handleClick(o) {
      this.internalValue = !this.internalValue
    }
  }
}
