
export default {
  name: 'AtomContentThumbSliderBrochure',
  computed: {
    index() {
      return this.$vnode.data.key
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.index)
    }
  }
}
