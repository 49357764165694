
export default {
  name: 'AtomArrowButtonSlider',
  props: {
    right: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      bHovered: false,
      show: false,
      showMessage: false
    }
  },
  methods: {
    hovered: function () {
      this.bHovered = true
      this.$el.classList.add('hovered')
    },
    unhovered: function () {
      this.bHovered = false
      this.$el.classList.remove('hovered')
    },
    clickedPrevious: function () {
      this.$emit('Previous')
    },
    clickedNext: function () {
      this.$emit('Next')
    }
  }
}
