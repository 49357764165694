
export default {
  name: 'AtomCollapsibleItem',
  props: {
    item: {
      required: false,
      type: Object,
      default: () => {}
    },
    isDefaultOpen: {
      required: false,
      type: Boolean,
      default: false
    },
    showIconText: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.$props.isDefaultOpen
    }
  },
  computed: {
    computedText() {
      return this.isOpen ? 'minimize' : 'expand'
    }
  },
  methods: {
    toggle(emit = true) {
      this.isOpen = !this.isOpen
      if (emit) {
        this.$emit('toggle', this.isOpen)
      }
    },
    open(emit = true) {
      this.isOpen = true
      if (emit) {
        this.$emit('toggle', this.isOpen)
      }
    },
    close(emit = true) {
      this.isOpen = false
      if (emit) {
        this.$emit('toggle', this.isOpen)
      }
    }
  }
}
