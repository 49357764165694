
import 'vue-slider-component/dist-css/vue-slider-component.css'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'AtomDoubleRangeSlider',
  props: {
    value: {
      required: true,
      type: [Number, Array]
    },
    min: {
      required: false,
      type: Number,
      default: 0
    },
    max: {
      required: false,
      type: Number,
      default: 100
    },
    tooltipFormatterRule: {
      required: true,
      type: String,
      default: 'priceRule'
    },
    tooltip: {
      required: false,
      type: String,
      default: 'none'
    },
    minRange: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      marks: [this.min, this.max]
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    minRangeValue() {
      if (!this.minRange) return 0
      return (this.max - this.min) * 0.2
    },
    formatRange() {
      let min = this.value[0] || 0
      let max = this.value[1] || 0
      if (this.tooltipFormatterRule === 'priceRule') {
        min = min === 1000000 ? '1M' : ~~(min / 1000) + (min / 1000 >= 1 ? 'K' : '')
        max = max === 1000000 ? '1M' : ~~(max / 1000) + (max / 1000 >= 1 ? 'K' : '')
      }
      return {
        min,
        max
      }
    }
  }
}
